<!--
 打印设计
-->
<template>
  <div v-loading="divloading" style="width: 100%; padding: 20px" width="100%" :visible="visible" destroyOnClose
    :closable="false" @close="visible = false">
    <a-row style="margin-bottom: 10px">
      <!-- <a-button type="primary" @click="returnclick">返回</a-button> -->

      <div style="display: inline-block; margin-bottom: 10px">
        纸张大小:&nbsp;
        <a-radio-group style="margin-left: 10px" v-model="keyType" button-style="solid">
          <!-- @change="changekeytype" -->
          <a-tooltip placement="top" v-for="item in paperTypes" :key="item.key">
            <template slot="title">
              <span>宽：{{ item.width }}cm</span>
              <span>&nbsp;--&nbsp;</span>
              <span>高：{{ item.height }}cm</span>
            </template>
            <a-radio-button :value="item.key" @click="clickkeytype(item)">
              {{ item.key }}
            </a-radio-button>
          </a-tooltip>

          <a-popover title="自定义宽(cm)高(cm)" trigger="click" v-model="paperPopVisible">
            <template slot="content">
              <div>
                宽：<a-input-number v-model="Customize.width" :min="5" style="width: 70px" />&nbsp; 高：<a-input-number
                  v-model="Customize.height" :min="5" style="width: 70px" />
              </div>

              <div style="text-align: center; margin-top: 10px">
                <!-- <a-popconfirm
                  title="切换纸张大小会重置模板内容，确定切换吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="Customizeyes"
                > -->
                <a-button type="param" @click="clickkeytype(Customize)">确定</a-button>
                <!-- </a-popconfirm> -->
              </div>
            </template>
            <a-radio-button :key="'自定义'" :value="'自定义'">
              自定义
            </a-radio-button>
          </a-popover>
        </a-radio-group>
      </div>
      <div style="display: inline-block; margin-left: 10px; margin-bottom: 10px">
        打印方向：&nbsp;
        <!-- <a-radio-group v-model="printsize" button-style="solid">
          <a-radio-button @click="cickprint('portrait')"
            >纵向打印</a-radio-button
          >
          <a-radio-button>横向打印</a-radio-button></a-radio-group
        > -->
        <!-- @change="printsizechange" -->

        <a-radio-group v-model="printsize" button-style="solid" @change="cickprint">
          <a-radio-button value="portrait"> 纵向打印 </a-radio-button>

          <a-radio-button value="landscape"> 横向打印 </a-radio-button>
        </a-radio-group>
      </div>
      <a-button style="margin-left: 10px; margin-bottom: 10px" type="primary" v-print="'#print'">打印</a-button>
      <a-popconfirm style="margin-left: 10px; margin-bottom: 10px" title="是否确认清空?" cancelText="取消" okText="确定清空"
        okType="danger" @confirm="reset">
        <a-icon slot="icon" type="question-circle-o" style="color: red" />
        <a-button type="danger">
          清空
          <a-icon type="close" />
        </a-button>
      </a-popconfirm>

      <!-- <a-button @click="gethuoqu" style="margin-left: 10px" type="primary"
        >获取数据</a-button
      > -->
      <div style="display: inline-block; margin-left: 10px; margin-bottom: 10px">
        选择公司：&nbsp;
        <CompanySearchSelect style="display: inline-block" v-model="CompanyId" @select="changeSearchSelect"
          size="small">
        </CompanySearchSelect>
        &nbsp;&nbsp;&nbsp;&nbsp;选择模板：&nbsp;
        <el-select style="width: 200px" v-model="Amodel" @change="TemNameChange" size="small" :loading="Temloading">
          <el-option v-for="(item, index) in template" :key="item.Id" :value="item.Id" :label="item.Initialtemplate
            ? item.TemName + '（默认模板）'
            : item.TemName
            ">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">签字数量：</span>
        <a-select style="width: 60px" v-model="SignNum" @change="TemNameSignNumChange">
          <a-select-option :value="item" v-for="(item, index) in templateSignNum" :key="index">
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <!-- <a-popconfirm style="margin-left: 10px; margin-bottom: 10px" title="是否确认保存模板?" cancelText="取消" okText="确定"
        @confirm="subbaocun">

        <a-button style="margin-left: 10px; margin-bottom: 10px" type="primary">保存模板</a-button>
      </a-popconfirm> -->
      <a-button style="margin-left: 10px; margin-bottom: 10px" type="primary" @click="() => {
        this.modalDelPop = true;
      }
        ">
        删除模板
      </a-button>
      <a-button style="margin-left: 10px; margin-bottom: 10px" type="primary" @click="() => {
        this.modalflag = true;
      }
        ">保存模板</a-button>
    </a-row>
    <a-row style="margin-bottom: 10px">
      是否加载附件：
      <a-radio-group style="margin-left: 10px" v-model="isAccessory" button-style="solid">
        <a-radio-button :value="true"> 是 </a-radio-button>
        <a-radio-button :value="false"> 否 </a-radio-button>
      </a-radio-group>
      &nbsp; 加载评价：
      <a-checkbox-group v-model="checkGroup" name="checkboxgroup" :options="checkGroupList" />
      &nbsp; 是否隐藏表格空行：
      <a-radio-group style="margin-left: 10px" v-model="showEmpty" button-style="solid">
        <a-radio-button :value="1"> 是 </a-radio-button>
        <a-radio-button :value="0"> 否 </a-radio-button>
      </a-radio-group>
    </a-row>
    <a-row :gutter="[8, 0]">
      <a-col :span="4">
        <a-card style="height: 100vh; overflow: auto">
          <a-row style="
              position: sticky;

              top: 0px;
              z-index: 55;
              background-color: #fff;
              padding-top: 15px;
              padding-bottom: 15px;
            ">
            <a-input-search placeholder="搜索组件标签" style="width: 100%" v-model="search" @search="onSearch"
              @input="onSearch" />
          </a-row>

          <a-row v-for="item in list" :key="item.Keys + item.Id" style="margin-bottom: 15px; position: relative">
            <!-- <a-button
              style="width: 100%"
              :type="
                item.type === '3' || item.Keys === 'Signs' ? 'primary' : ''
              "
              @click="addprint(item)"
              >{{ item.Key }}</a-button
            >
            <a-tag
              style="
                margin: 0;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
              "
              :color="item.GroupType === 0 ? 'cyan' : 'blue'"
            >
              {{ item.GroupType === 0 ? "发货" : "收货" }}
            </a-tag> -->
            <div @dragstart="dragstartHandler($event, item)" @dragend="onDragEnd" draggable="true">
              <a-button style="width: 100%" :type="item.type === '3' || item.Keys === 'Signs' ? 'primary' : ''
                ">
                {{ item.Key }}
              </a-button>
              <!-- @click="addprint(item)" -->
              <a-tag style="
                  margin: 0;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  right: 0;
                  display: flex;
                  align-items: center;
                " :color="item.GroupType === 0 ? 'cyan' : 'blue'">
                {{ item.GroupType === 0 ? "发货" : "收货" }}
              </a-tag>
            </div>
          </a-row>
        </a-card>
      </a-col>

      <a-col v-loading="loading" :span="16" :style="`overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;height: 100vh;`" @click.self="divcalck">
        <div :style="`width: ${styleW}cm;height: ${styleH * (Number(printPages) > 1 ? Number(printPages) : 1)
          }cm;border:1px solid #ccc;margin:auto`">
          <div class="print-helper" :style="`width: ${styleW}cm;height: ${styleH * (Number(printPages) > 1 ? Number(printPages) : 1)
            }cm;margin:auto`">
            <div v-for="(item, inx) in helperNumber" :key="inx" class="print-helper-list" :style="getHelperListStyle()">
            </div>
          </div>

          <div id="print" :style="`position: relative;width: ${styleW}cm;height: ${styleH * (Number(printPages) > 1 ? Number(printPages) : 1)
            }cm;margin: auto;`" @drop="dropHandler" @dragover="dragoverHandler" @click.self="divcalck">
            <!-- snapToGrid -->
            <!-- :stickSize="12" -->
            <vue-drag-resize style="cursor: grab" :stickSize="12" v-for="(item, index) in printlist" :key="item.Id"
              snapToGrid :gridX="2" :gridY="2" :isActive="item.isActive" :preventActiveBehavior="item.isActive"
              :isDraggable="item.isDraggable" :isResizable="item.isResizable" :aspectRatio="(item.type === '3' &&
                !Type3UploadImgKeys.filter((e) => e != 'PayQrcode').includes(
                  item.Keys
                )) ||
                item.type === '7'
                ? true
                : false
                " parentLimitation :parentW="getpx(styleW, 'w', 'px')" :parentH="getpx(
                  styleH * (Number(printPages) > 1 ? Number(printPages) : 1),
                  'h',
                  'px'
                )
                  " :w="item.w" :h="item.h" :x="item.x" :y="item.y" :z="item.type === '10' ? 1 : 2" :minh="minh(item)"
              :minw="item.type === '9'
                ? 5
                : item.type === '4'
                  ? getpx(styleW, 'w', 'px') * 0.4
                  : 40
                " @clicked="clicked(item)" @deactivated="deactivated" @resizing="resizing($event, item)"
              @resizestop="resizestop($event, item)" @dragging="dragging($event, item)"
              @dragstop="dragstop($event, item)" @activated="activated(item, index)">
              <div v-if="item.type === '1' || item.type === '12'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
                <p>{{ item.value }}</p>
              </div>
              <div v-if="item.type === '5'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
                <p>{{ item.Key }}</p>
              </div>
              <div
                :style="`display: flex;width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`"
                v-if="item.type === '2' || item.type === '6'">
                <div style="height: 100%" :ref="item.key + item.Id">
                  {{ item.Key }}&nbsp;
                </div>
                <div :style="`flex:1;height: 100%;border-bottom: 1px solid #000;text-align: ${item.align};`">
                  {{ item.value }}
                </div>
              </div>
              <div v-if="item.type === '3'" style="width: 100%; height: 100%">
                <img :src="item.value" alt="" width="100%" height="100%" />
              </div>

              <div v-if="item.type === '4'" style="width: 100%; height: 100%">
                <table border="1" style="width: 100%; height: auto">
                  <thead :style="`background-color: #ccc; box-sizing: border-box;font-family:${item.family} `">
                    <td v-for="(val, index) in item.columns" :key="val.dataIndex" :style="`width: ${val.width || 50}px;line-height: ${val.lineHeight || 20
                      }px;  text-align: ${val.align || 'center'};font-weight:${val.bold || 'initial'
                      };font-size:${val.fontSize || 18}px`">
                      {{ val.title }}
                    </td>
                  </thead>

                  <tr v-for="(itemdata, index) in item.datalist" :key="index">
                    <td v-for="(val, index) in item.columns" :key="index"
                      :style="`text-align: ${val.textalign || 'center'};`">
                      <div :style="`text-align: ${val.textalign || 'center'
                        };height:${val.textlineHeight || 20
                        }px;overflow: hidden;`">
                        {{ itemdata[val.dataIndex] || "&nbsp;" }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div v-if="item.type === '7'">
                <!-- <div style="text-align: center">{{ item.Key }}</div> -->
                <vue-qr :margin="0" :text="item.value || ''" :logoScale="0.2" :size="item.w"></vue-qr>
              </div>
              <div v-if="item.type === '8'" style="width: 100%; height: 100%">
                <p :style="`background-color: #000; width: 100%; height: ${item.pxh}px`"></p>
              </div>
              <div v-if="item.type === '9'" style="width: 100%; height: 100%">
                <p :style="`background-color: #000; width: ${item.pxh}px; height: 100%`"></p>
              </div>
              <div v-if="item.type === '10'" style="width: 100%; height: 100%">
                <div :style="`width: 100%; height: 100%; border: ${item.pxh}px solid #000`"></div>
              </div>
              <div v-if="item.type === '11'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
                <p>{{ item.Key }}{{ item.value }}</p>
              </div>
            </vue-drag-resize>
          </div>
        </div>
      </a-col>
      <a-col :span="4">
        <a-card>
          <a-form-model :model="form" v-if="form.Id">
            <div v-if="
              form.type === '1' ||
              form.type === '2' ||
              form.type === '5' ||
              form.type === '6' ||
              form.type === '11' ||
              form.type === '12'
            ">
              <a-row>
                <a-form-model-item :label="form.Key">
                  <a-input v-model="form.Key" />
                  <a-input v-model="form.value" type="textarea" v-if="form.type != '5'" />
                </a-form-model-item>
              </a-row>
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="字体大小(px)">
                    <a-select v-model="form.fontSize" size="small">
                      <a-select-option :value="item" v-for="(item, index) in fontSizeList" :key="index">
                        {{ item }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="粗体">
                    <a-select v-model="form.bold" size="small">
                      <a-select-option :value="item.value" v-for="(item, index) in boldList" :key="index">
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="15">
                <a-col :span="12" v-if="form.type === '2' || form.type === '6'">
                  <a-form-model-item label="内容对齐">
                    <a-select v-model="form.align" size="small">
                      <a-select-option :value="item.value" v-for="(item, index) in alignList" :key="index">
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="字体行高(px)">
                    <a-select v-model="form.lineHeight" size="small">
                      <a-select-option :value="item" v-for="(item, index) in lineHeightList" :key="index">
                        {{ item }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="索引排序">
                    <a-input-number v-model="form.index" :min="0" size="small" style="width: 100%">
                    </a-input-number>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="是否外部引入">
                    <a-select v-model="form.isFormTrue" size="small" disabled @change="isFormTruechange">
                      <a-select-option value="true"> 是 </a-select-option>
                      <a-select-option value="false"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="仅打印时显示">
                    <a-select v-model="form.ispringt" :disabled="form.type !== '2' && form.type !== '6'" size="small">
                      <!-- @change="ispringtchange" -->
                      <a-select-option :value="1"> 是 </a-select-option>
                      <a-select-option :value="0"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="是否必填">
                    <a-select v-model="form.isrules" size="small">
                      <!-- || form.ispringt === 1 -->
                      <!-- @change="isruleschange" -->
                      <a-select-option :value="1"> 是 </a-select-option>
                      <a-select-option :value="0"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="x">
                    <a-input-number size="small" v-model="form.x"></a-input-number>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="y">
                    <a-input-number size="small" v-model="form.y"></a-input-number>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <div style="
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    margin-top: 50px;
                  ">
                  <a-button @click="del(form)" type="danger">删除</a-button>
                  <a-button @click="formyes(form)" type="primary">确定</a-button>
                </div>
              </a-row>
            </div>
            <a-form-model-item :label="form.Key" v-if="form.type === '3'">
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="仅打印时显示">
                    <a-select v-model="form.ispringt" :disabled="form.type !== '2' && form.type !== '6'" size="small">
                      <a-select-option :value="1"> 是 </a-select-option>
                      <a-select-option :value="0"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="15" v-if="Type3UploadImgKeys.includes(form.Keys)">
                <a-col :span="12">
                  <a-form-model-item label="上传图片">
                    <ImgUpload @change="updataImgUrl"></ImgUpload>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <div style="
                  width: 100%;
                  display: flex;
                  justify-content: space-around;
                  margin-top: 50px;
                ">
                <a-button @click="del(form)" type="danger">删除</a-button>
                <a-button @click="formyes(form)" type="primary">确定</a-button>
              </div>
            </a-form-model-item>
            <a-form-model-item :label="form.Key" v-if="form.type === '4'">
              <a-radio-group v-model="tabletabs" style="margin-bottom: 16px" size="small">
                <a-radio-button value="列设置"> 列设置 </a-radio-button>
                <a-radio-button value="添加列"> 添加列 </a-radio-button>
                <a-radio-button value="删除列"> 删除列 </a-radio-button>
                <a-radio-button value="列排序"> 列排序 </a-radio-button>
              </a-radio-group>
              <el-row :gutter="15">
                <el-col :span="12">
                  <a-form-model-item label="行数">
                    <a-input-number :min="1" v-model="form.rowCount" style="width: 100%"></a-input-number>
                  </a-form-model-item>
                </el-col>
                <el-col :span="12">
                  <a-form-model-item label="表头字体">
                    <a-select v-model="form.family">
                      <a-select-option :value="item.value" v-for="(item, index) in familyList" :key="index">
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </el-col>
              </el-row>
              <div v-if="tabletabs === '列设置'">
                <a-row>
                  选择列
                  <a-select style="width: 100%" placeholder="选择列" v-model="theadmodel" @change="theadchange">
                    <a-select-option v-for="(item, index) in form.columns" :key="item.dataIndex"
                      :value="item.dataIndex">
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </a-row>
                <div v-if="theadmodel">
                  <a-row>
                    设置列别名
                    <a-input v-model="formobj.title"></a-input>
                  </a-row>
                  <a-row :gutter="15">
                    <a-col :span="12">
                      <a-form-model-item label="绑定数据源">
                        <a-select v-model="formobj.newDataIndex" size="small">
                          <a-select-option :value="item.Value" v-for="(item, index) in colList" :key="index" :disabled="form.columns.findIndex(
                            (im) => im.dataIndex == item.Value
                          ) != -1
                            ">
                            {{ item.Key }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-model-item label="是否激发事件">
                        <a-select v-model="formobj.actionCol" size="small">
                          <a-select-option value="1"> 是 </a-select-option>
                          <a-select-option value="0"> 否 </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <div v-if="formobj.actionCol == '1'">
                    <a-row :gutter="15">
                      <a-col :span="12">
                        <a-form-model-item label="计算列(1)">
                          <a-select v-model="formobj.actionA" size="small">
                            <a-select-option v-for="item in form.columns" :key="item.dataIndex" :value="item.dataIndex">
                              {{ item.title }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-model-item label="计算列(2)">
                          <a-select v-model="formobj.actionB" size="small">
                            <a-select-option v-for="item in form.columns" :key="item.dataIndex" :value="item.dataIndex">
                              {{ item.title }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row :gutter="15">
                      <a-col :span="12">
                        <a-form-model-item label="运算符">
                          <a-select v-model="formobj.actionC" size="small">
                            <a-select-option value="0"> 加(+) </a-select-option>
                            <a-select-option value="1"> 减(-) </a-select-option>
                            <a-select-option value="2"> 乘(*) </a-select-option>
                            <a-select-option value="3"> 除(/) </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-model-item label="结果列">
                          <a-select v-model="formobj.actionD" size="small">
                            <a-select-option v-for="item in form.columns" :key="item.dataIndex" :value="item.dataIndex">
                              {{ item.title }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </div>
                  <a-row :gutter="15">
                    <a-col :span="12">
                      <a-form-model-item label="表头对齐">
                        <a-select v-model="formobj.align" size="small">
                          <a-select-option :value="item.value" v-for="(item, index) in alignList" :key="index">
                            {{ item.key }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-model-item label="索引排序">
                        <a-input-number :min="0" size="small" v-model="formobj.sortIndex"></a-input-number>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="15">
                    <a-col :span="12">
                      <a-form-model-item label="列宽度(px)">
                        <a-input-number :min="1" v-model="formobj.width" size="small" />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-model-item label="表头列行高(px)">
                        <a-input-number :min="1" v-model="formobj.lineHeight" size="small" />
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="15">
                    <a-col :span="12">
                      <a-form-model-item label="表头粗体">
                        <a-select v-model="formobj.bold" size="small">
                          <a-select-option :value="item.value" v-for="(item, index) in boldList" :key="index">
                            {{ item.key }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-model-item label="表头字体大小">
                        <a-select v-model="formobj.fontSize" size="small">
                          <a-select-option :value="item" v-for="(item, index) in fontSizeList" :key="index">
                            {{ item }}(px)
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="15">
                    <a-col :span="12">
                      <a-form-model-item label="内容对齐">
                        <a-select v-model="formobj.textalign" size="small">
                          <a-select-option :value="item.value" v-for="(item, index) in alignList" :key="index">
                            {{ item.key }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-model-item label="内容行高(px)">
                        <a-input-number :min="1" v-model="formobj.textlineHeight" size="small" />
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="15">
                    <a-col :span="12">
                      <a-form-model-item label="内容类型">
                        <a-select v-model="formobj.type" size="small">
                          <a-select-option value="text"> 文本 </a-select-option>
                          <a-select-option value="number">
                            数字
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12" v-if="formobj.type === 'text'">
                      <a-form-model-item label="最大长度">
                        <a-input-number v-model="formobj.maxlength" :min="0" size="small">
                        </a-input-number>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12" v-if="formobj.type === 'number'">
                      <a-form-model-item label="小数位">
                        <a-input-number v-model="formobj.decimals" size="small" :min="0" :max="10" />
                      </a-form-model-item>
                    </a-col>
                  </a-row>

                  <a-row :gutter="15">
                    <a-col :span="12">
                      <a-form-model-item label="签收显示">
                        <a-select v-model="formobj.signShowColumn" size="small">
                          <a-select-option value="0"> 不显示 </a-select-option>
                          <a-select-option value="1"> 显示 </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-model-item label="签收统计列">
                        <a-select v-model="formobj.signComparisonColumn" size="small">
                          <a-select-option value="0"> 否 </a-select-option>
                          <a-select-option value="1"> 是 </a-select-option>
                          <!-- <a-select-option :value="item.Value" v-for="(item, index) in colList" :key="index" :disabled="form.columns.findIndex(
                              (im) => im.dataIndex == item.Value
                            ) != -1
                              ">
                              {{ item.Key }}
                            </a-select-option> -->
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </div>
                <a-row>
                  <a-button @click="formyes(form)" type="primary">确定</a-button>
                </a-row>
              </div>
              <div v-if="tabletabs === '添加列'">
                <a-row>
                  <a-form-model-item label="表头列名称">
                    <a-input v-model="addtablelie" size="small" />
                  </a-form-model-item>
                </a-row>
                <a-row>
                  <a-button @click="addtablelieyes(form)" type="primary">确定</a-button>
                </a-row>
              </div>
              <div v-if="tabletabs === '删除列'">
                <a-row>
                  选择列
                  <a-select style="width: 100%" placeholder="选择列" v-model="deletelieList" size="small" mode="multiple">
                    <a-select-option v-for="(item, index) in form.columns" :key="item.dataIndex"
                      :value="item.dataIndex">
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </a-row>
                <a-row>
                  <a-button @click="dleetlieyes(form)" type="primary">删除列</a-button>
                </a-row>
              </div>
              <div v-if="tabletabs === '列排序'">
                <div class="flexBox" v-for="item in form.columns" :key="item.dataIndex">
                  <span class="flex1">{{ item.title }}:</span>
                  <a-input-number class="flex1" :min="0" v-model="item.sortIndex"></a-input-number>
                </div>
              </div>
              <a-row v-if="tabletabs === '列排序'">
                <a-button @click="sortColumns(form)" type="primary" style="width: 100%">确认</a-button>
              </a-row>
              <a-row>
                <a-button @click="del(form)" type="danger" style="width: 100%; margin-top: 50px">删除表格</a-button>
              </a-row>
            </a-form-model-item>
            <a-row v-if="form.type === '7'">
              <a-form-model-item :label="form.Key">
                <a-input v-model="form.value" type="textarea" />
              </a-form-model-item>
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="仅打印时显示">
                    <a-select v-model="form.ispringt" :disabled="form.type !== '2' && form.type !== '6'" size="small">
                      <a-select-option :value="1"> 是 </a-select-option>
                      <a-select-option :value="0"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>

                <!-- <a-col :span="12">
                  <a-form-model-item label="是否必填">
                    <a-select v-model="form.isrules" size="small">
                      <a-select-option :value="1"> 是 </a-select-option>
                      <a-select-option :value="0"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col> -->
              </a-row>
              <a-row>
                <div style="
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    margin-top: 50px;
                  ">
                  <a-button @click="del(form)" type="danger">删除</a-button>
                  <a-button @click="formyes(form)" type="primary">确定</a-button>
                </div>
              </a-row>
            </a-row>
            <div v-if="
              form.type === '8' || form.type === '9' || form.type === '10'
            ">
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item :label="`${form.Key}深度`">
                    <a-select v-model="form.pxh" size="small">
                      <a-select-option :value="va" v-for="va in 10" :key="va">
                        {{ va }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <div style="
                  width: 100%;
                  display: flex;
                  justify-content: space-around;
                  margin-top: 50px;
                ">
                <a-button @click="del(form)" type="danger">删除</a-button>
                <a-button @click="formyes(form)" type="primary">确定</a-button>
              </div>
            </div>
          </a-form-model>
        </a-card>
      </a-col>
    </a-row>

    <div v-html="styleText"></div>
    <a-modal title="保存模板" destroyOnClose :visible="modalflag" :confirm-loading="loading" @ok="handleOk" okText="确定"
      cancelText="取消" @cancel="modalflag = false">
      <a-form-model layout="horizontal" ref="ruleForm" :model="subform" v-bind="{
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      }" :rules="rules">
        <a-form-model-item label="模板类型"> 表单模板 </a-form-model-item>
        <a-form-model-item label="模板名称" prop="TemName">
          <a-input v-model="subform.TemName" />
        </a-form-model-item>
        <a-form-model-item label="是否默认模板" prop="Initialtemplate">
          <a-radio-group style="margin-left: 10px" v-model="subform.Initialtemplate" button-style="solid">
            <a-radio-button :value="true"> 是 </a-radio-button>
            <a-radio-button :value="false"> 否 </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="是否覆盖当前模板">
          <a-radio-group style="margin-left: 10px" v-model="isCover" button-style="solid">
            <a-radio-button :value="true"> 是 </a-radio-button>
            <a-radio-button :value="false"> 否 </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title="删除模板" destroyOnClose :visible.sync="modalDelPop" width="300px" @ok="modalDel" okText="确定"
      cancelText="取消" @cancel="modalDelPop = false">
      <div>
        <a-checkbox-group @change="selectDelModel">
          <div v-for="(item, inx) in template" :key="inx">
            <a-checkbox :value="item.Id">{{ item.TemName }}</a-checkbox>
          </div>
        </a-checkbox-group>
      </div>
    </a-modal>
    <!-- 删除确认 -->
    <a-modal title="删除确认" :visible="visibleConfirmDelModel" :confirm-loading="confirmDelModelLoading"
      @ok="handleOkToDelModel" @cancel="this.visibleConfirmDelModel = false">
      <p>确定删除模板么？</p>
    </a-modal>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
import ImgUpload from "@/components/ImgUpload.vue";
function jsgetDPI() {
  var arrDPI = new Array();

  if (window.screen.deviceXDPI != undefined) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement("DIV");
    tmpNode.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}

export default {
  data() {
    return {
      //签字数量
      SignNum: 1,
      // 签字数量下拉列表数据
      templateSignNum: [1, 2, 3, 4, 5],
      divloading: false,
      isAccessory: true,
      showEmpty: 1, //是否显示空行
      Temloading: false, //模板加载
      CompanyId: "",
      fontSizeList: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34],
      checkGroupList: ["服务", "物流", "产品"],
      checkGroup: [],
      colList: [],
      boldList: [
        { key: "默认", value: "initial" },
        { key: "加粗", value: "bold" },
        { key: "100", value: "100" },
        { key: "200", value: "200" },
        { key: "300", value: "300" },
        { key: "400", value: "400" },
        { key: "500", value: "500" },
        { key: "600", value: "600" },
        { key: "700", value: "700" },
        { key: "800", value: "800" },
        { key: "900", value: "900" },
      ],
      alignList: [
        { key: "左对齐", value: "left" },
        { key: "居中", value: "center" },
        { key: "右对齐", value: "right" },
      ],
      familyList: [
        { key: "黑体", value: "黑体" },
        { key: "宋体", value: "宋体" },
        { key: "雅黑", value: "雅黑" },
      ],
      lineHeightList: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46],
      subform: {
        TemName: "",
        Content: "",
        TemCode: "",
        Type: 0,
        IndustryId: "",
        SearchKey: "",
        CasePinYin: "",
        CompanyId: "",
        TemType: 0,
        Initialtemplate: true,
      },
      isCover: false,
      modalflag: false,
      loading: false,
      form: {},
      printlist: [],
      keyType: "A4",
      printPages: 1,
      printsize: "portrait",
      Customize: {
        key: "自定义",
        width: 25,
        height: 17.26,
      },

      paperTypes: [
        // {
        //   key: 'A3',
        //   width: 42,
        //   height: 29.6,
        // },
        {
          key: "A4",
          width: 21,
          height: 29,
        },
        {
          key: "A5",
          width: 21,
          height: 14.5,
        },
        // {
        //   key: 'B3',
        //   width: 50,
        //   height: 35.26,
        // },
        // {
        //   key: 'B4',
        //   width: 25,
        //   height: 35.26,
        // },
        // {
        //   key: 'B5',
        //   width: 25,
        //   height: 17.26,
        // },
      ],

      visible: true,

      columns: [
        { title: "品名", dataIndex: "cInvName" },
        { title: "包装", dataIndex: "cInvStd" },
        { title: "批次号", dataIndex: "Batch" },
        { title: "单净重(KG)", dataIndex: "Weight" },
        {
          title: "数量",
          dataIndex: "Quantity",
        },
        {
          title: "总重量(KG)",
          dataIndex: "Weight1",
        },
        {
          title: "签收数量",
          dataIndex: "nums",
        },
      ],
      datalist: [{}, {}, {}, {}, {}],
      list: [],
      template: [],
      typeitem: {
        isActive: false, //激活状态
        isDraggable: true,
        isResizable: true,
        aspectRatio: true, //允许等比例缩放
        parentLimitation: true, //不允许超出父级元素
        ispringt: 1, //仅打印时显示
        isrules: 1, //是否必填显示
        parentW: this.getpx(this.styleW, "w", "px"),
        parentH: this.getpx(this.styleH, "H", "px"),
        // w: 260,
        // h: 28,
        x: 0,
        y: 0,
      },
      type: "",
      parentW: null,
      parentH: null,
      styleW: 21,
      styleH: 29,
      paperPopVisible: false,
      rules: {
        TemName: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
        ],
        Initialtemplate: [
          { required: true, message: "请选择是否默认模板", trigger: "change" },
        ],
      },
      Amodel: "",
      thead: [
        {
          name: "名称",
          key: "name",
        },
      ],
      headlist: {
        name: "名称",
        key: "name",
      },
      theadmodel: "",
      datlist: [
        {
          key: "name",
          value: "恩恩额",
        },
      ],
      trueenflase: [
        { keys: "是", value: true },
        { keys: "否", value: false },
      ],
      formobj: {},
      search: "",
      addtablelie: "",
      deletelieList: [],
      tabletabs: "列设置",
      tablerowtabs: "行设置",
      list2: [],
      styleText:
        '<style type="text/css" media="print">\n' +
        "  @page { size: portrait; }\n" +
        "</style>",
      modalDelPop: false, //删除模板弹窗
      delModleList: [],
      confirmDelModelLoading: false,
      visibleConfirmDelModel: false,
      helperNumber: 1260, //推荐数量看 getHelperListStyle for循环
      Type3UploadImgKeys: ["PayQrcode", "BusinessCard", "ManifestImg"], //type3 中可上传图片得组件
    };
  },
  components: {
    CompanySearchSelect,
    VueQr,
    ImgUpload,
  },
  created() {
    // console.log(window.screen.deviceXDPI)
    this.getCompanyIdList();
    this.getbutlist();
  },
  mounted() {
    document.addEventListener('keydown', this.keyDow);
  },
  destroyed() {
    document.removeEventListener('keydown', this.keyDow);
  },
  methods: {
    keyDow(event) {
      if (event.keyCode === 8 || event.keyCode === 46) {
        if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
          return
        } else {
          this.del(this.form)
        }
      }
    },
    // 签字数量改变
    TemNameSignNumChange(a) {
      console.log("签字数量", a);
      this.SignNum = a;
    },
    reset() {
      this.printlist = [];
      this.form = {};
      // this.Amodel = '';
      // this.subform = {}
    },
    // 列排序
    sortColumns(form) {
      form.columns = form.columns.sort((a, b) => {
        return (a.sortIndex || 0) - (b.sortIndex || 0);
      });
      let index = this.printlist.findIndex((item) => item.Id == form.Id);
      this.printlist[index] = form;
      this.form = {};
      this.formobj = {};
    },
    onSearch() {
      console.log(this.search);
      this.list = this.list2.filter((item) => {
        return item.Key.indexOf(this.search) !== -1;
      });
    },
    minh(item) {
      if (item.type === "8") {
        return 5;
      }
      if (item.type === "4") {
        if (this.keyType === "A4") {
          return 50;
        } else {
          return 138;
        }
      }
      return 28;
    },
    clickkeytype(item) {
      if (item.key === "自定义") {
      } else {
        if (item.key === this.keyType) {
          return;
        }
      }

      // console.log(item.key)
      var keytype = this.keyType;
      // console.log(this.keyType)
      this.$confirm("切换纸张大小会重置模板内容，确定切换吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (item.key === "自定义") {
            this.Customizeyes();
          } else {
            this.changekeytype();
          }
        })
        .catch(() => {
          this.keyType = keytype;
        });
    },
    cickprint(portrait) {
      // console.log(this.printsize)
      this.$confirm("切换打印方向会重置模板内容，确定切换吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.printsizechange(false);
        })
        .catch(() => {
          if (this.printsize === "portrait") {
            this.printsize = "landscape";
          } else {
            this.printsize = "portrait";
          }

          // console.log(this.printsize)
        });
    },
    confirmkeyType(key) {
      this.keyType = key;
      this.changekeytype();
    },
    confirmprintsize2() {
      this.printsize = "portrait";
      this.printsizechange();
    },
    confirmprintsize() {
      this.printsize = "landscape";
      this.printsizechange();
    },
    isFormTruechange(e) {
      //console.log(this.form)
    },
    async changeSearchSelect(a) {
      console.log("选择好公司id时==", a, this.CompanyId);
      this.Temloading = true;
      await this.$http
        .post("/Base/Base_Template/GetTemplateDataList", {
          Search: {
            CompanyId: a.Id,
          },
        })
        .then((res) => {
          if (res.Success) {
            this.template = res.Data;
            // var printObj = this.template.find((item) => {
            //   return item.Id === a;
            // });

            if (res.Data.length > 0) {
              let obj = this.template.find((item) => {
                return item.Initialtemplate;
              });

              console.log(
                "选择好公司了----------------------",
                a,
                obj,
                this.template,
                this.printlist
              );

              if (obj) {
                this.subform = { ...obj };
                this.Amodel = obj.Id;
                this.SignNum = obj.SignNum;
                this.TemNameChange(obj.Id);
              } else {
                let lastObj = this.template[this.template.length - 1];
                this.subform = { ...lastObj };
                this.Amodel = lastObj.Id;
                this.SignNum = lastObj.SignNum;
              }
            } else {
              this.subform = {
                TemName: "",
                Content: "",
                TemCode: "",
                Type: 0,
                IndustryId: "",
                SearchKey: "",
                CasePinYin: "",
                CompanyId: "",
                TemType: 0,
                Initialtemplate: true,
              };
            }
          } else {
            this.Amodel = "";
            this.printlist = [];
            this.template = [];
          }
          this.Temloading = false;
        });
      return;
      this.loading = true;
      this.$http
        .post(`/Base/Base_Template/GetCompanyTemp?CompayId=${a.Id}`, {})
        .then((res) => {
          if (res.Success) {
            this.printlist = [];
            setTimeout(() => {
              var obj = JSON.parse(res.Data.Content);
              this.keyType = obj.keyType;
              this.printPages = obj.printPages;
              this.printsize = obj.printsize;
              this.styleH = obj.styleH;
              this.styleW = obj.styleW;
              this.printlist = obj.printlist;
              this.checkGroup = obj.evaluation;
            }, 1);
            this.getBindColList();
          } else {
            this.$message.warning(res.Msg);
          }
          this.loading = false;
        });
    },
    dleetlieyes(form) {
      if (this.deletelieList.length > 0) {
        var arr = form.columns.filter(
          (item) => !this.deletelieList.includes(item.dataIndex)
        );

        //console.log(arr)
        form.columns = arr;
        var index = this.printlist.findIndex((item) => {
          return item.Id === form.Id;
        });
        this.printlist[index] = form;
        this.form = {};
        this.formobj = {};
        this.deletelieList = [];
      } else {
        this.$message.error("请选择后删除");
      }
    },
    addtablelieyes(form) {
      if (this.addtablelie) {
        form.columns.push({
          title: this.addtablelie,
          dataIndex: new Date().getTime() + "" + this.addtablelie,
          type: "text",
          maxlength: "",
          decimals: "",
        });
        var index = this.printlist.findIndex((item) => {
          return item.Id === form.Id;
        });
        this.printlist[index] = form;
        this.form = {};
        this.formobj = {};
        this.addtablelie = "";
      } else {
        this.$message.error("请输入名称后确定");
      }
    },
    // 选中的列
    theadchange(a) {
      //console.log(a)
      var obj = this.form.columns.find((item) => {
        return item.dataIndex === a;
      });
      //console.log(obj)
      //console.log(this.form)
      this.formobj = { ...obj };
    },
    getbutlist() {
      this.$http.post("/Base/Base_ControL/GetDataList", {}).then((res) => {
        res.Data.forEach((item) => {
          this.$set(item, "lineHeight", 28);
          this.$set(item, "fontSize", "18");
          this.$set(item, "bold", "initial");
          this.$set(item, "align", "center");
        });

        this.list = res.Data.sort((a, b) => {
          return a.GroupType - b.GroupType;
        });
        this.list2 = res.Data.sort((a, b) => {
          return a.GroupType - b.GroupType;
        });
      });
    },
    gethuoqu() {
      //console.log()
      var obj = JSON.parse(localStorage.getItem("模板"));
      this.keyType = obj.keyType;
      this.printsize = obj.printsize;
      this.styleH = obj.styleH;
      this.styleW = obj.styleW;
      this.printlist = obj.printlist;
    },
    TemNameChange(a) {
      var printObj = this.template.find((item) => {
        return item.Id === a;
      });
      console.log(
        "找到渲染的当前模版------------------------",
        printObj,
        JSON.parse(printObj.Content)
      );

      this.printlist = [];
      this.form = {};
      setTimeout(() => {
        var obj = JSON.parse(printObj.Content) || {};
        this.keyType = obj.keyType;
        this.printPages = obj.printPages;
        this.printsize = obj.printsize;
        this.styleH = obj.styleH;
        this.styleW = obj.styleW;
        this.printlist = obj.printlist;
        this.checkGroup = obj.evaluation;
        this.showEmpty = obj.showEmpty ? obj.showEmpty : 0;
        this.isAccessory = obj.isAccessory;
      }, 1);
      this.getBindColList();
      this.subform = { ...printObj };
      this.Amodel = printObj.Id;
      this.SignNum = printObj.SignNum;
      //console.log(JSON.parse(str.Content))
    },
    // 保存模版弹窗确定
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          return;
        }
        var obj = {
          showEmpty: this.showEmpty, //是否隐藏空行
          // e==='自定义'?
          isAccessory: this.isAccessory,
          printPages: this.printPages, //打印张数
          keyType: this.keyType, //纸张类型
          styleW: this.styleW, //纸张宽度厘米
          styleH: this.styleH, ////纸张高度厘米
          printsize: this.printsize, //打印类型//横向 纵向
          printlist: this.printlist, //打印内容
          evaluation: this.checkGroup, //评价
        };

        this.subform.Content = JSON.stringify(obj);
        if (!this.isCover) {
          this.subform.Id = "";
        }

        //模板名称重复，只要名称一样，id 不一样就是重复，id名称一样的为更新
        let hasSameName = false;
        this.template.forEach((e) => {
          if (e.TemName === this.subform.TemName && this.subform.Id != e.Id) {
            console.log(e.TemName, this.subform.TemName, this.subform, e.Id);
            hasSameName = true;
          }
        });
        if (hasSameName && !this.isCover) {
          this.$message.error("模板名称重复");
          return;
        }
        this.loading = true;
        this.$http
          .post("/Base/Base_Template/SaveTemplateData", {
            ...this.subform,
            SignNum: this.SignNum,
          })
          .then(async (res) => {
            this.loading = false;
            if (res.Success) {
              this.$message.success("保存成功");
              this.modalflag = false;
              // 获取列表并更新当前保存模板;
              await this.changeSearchSelect({
                Id: this.CompanyId,
              });
              //this.TemNameChange(this.template[this.template.length - 1].Id);
              // this.subform = {
              //   "TemName": "",
              //   "Content": "",
              //   "TemCode": "",
              //   "Type": 0,
              //   "IndustryId": "",
              //   "SearchKey": "",
              //   "CasePinYin": "",
              //   "CompanyId": "",
              //   "TemType": 0,
              //   "Initialtemplate": true
              // };
            } else {
              this.$message.error("保存失败");
            }
          });
      });
    },
    subbaocun() {
      console.log(this.checkGroup);
      this.modalflag = true;
      return;
      var obj = {
        // e==='自定义'?
        isAccessory: this.isAccessory,
        printPages: this.printPages, //打印张数
        keyType: this.keyType, //纸张类型
        styleW: this.styleW, //纸张宽度厘米
        styleH: this.styleH, ////纸张高度厘米
        printsize: this.printsize, //打印类型//横向 纵向
        printlist: this.printlist, //打印内容
        evaluation: this.checkGroup, //评价
      };
      //console.log(obj, 'obj')
      this.loading = true;
      this.$http
        .post(
          "/Base/Base_Template/SaveData?isDefault=" + this.subform.isDefault,
          {
            CompanyId: this.CompanyId,
            Content: JSON.stringify(obj),
            TemType: 0,
          }
        )
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.$message.success("保存成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    printsizechange(isClear = true) {
      // //console.log(w, h)
      if (this.keyType === "自定义") {
        if (this.printsize === "portrait") {
          this.styleW = this.Customize.width;
          this.styleH = this.Customize.height;
        } else {
          this.styleH = this.Customize.width;
          this.styleW = this.Customize.height;
        }
        if (isClear) {
          this.printlist = [];
          this.form = {};
        }
        // this.Amodel = "";
        return;
      }
      var obj = this.paperTypes.find((item) => {
        return item.key === this.keyType;
      });

      if (this.printsize === "portrait") {
        this.styleW = obj.width;
        this.styleH = obj.height;
        this.styleText =
          '<style type="text/css" media="print">\n' +
          "  @page { size: portrait; }\n" +
          "</style>";
      } else {
        this.styleW = obj.height;
        this.styleH = obj.width;
        this.styleText =
          '<style type="text/css" media="print">\n' +
          "  @page { size: landscape; }\n" +
          "</style>";
      }
      //console.log(this.printsize)
      if (isClear) {
        this.printlist = [];
        this.form = {};
      }
      // this.Amodel = "";
      //console.log(this.styleH, 'styleH')
      //console.log(this.styleW, 'styleW')
    },
    Customizeyes() {
      this.keyType = this.Customize.key;
      this.styleW = this.Customize.width;
      this.styleH = this.Customize.height;
      this.paperPopVisible = false;
      this.printsize = "portrait";
      // this.printlist = [];
    },
    changekeytype() {
      var obj = this.paperTypes.find((item) => {
        return item.key === this.keyType;
      });
      this.styleW = obj.width;
      this.styleH = obj.height;
      //console.log(obj)
      //console.log(this.styleW)
      //console.log(this.styleH)
      this.printsize = "portrait";
      // this.printsizechange();

      // this.printlist = [];
    },
    getpx(num, wh, str) {
      var dpi = jsgetDPI();

      if (str == "px") {
        if (wh == "w") {
          return (num / 2.54) * dpi[0];
        }
        if (wh == "h") {
          return (num / 2.54) * dpi[1];
        }
      }
      if (str == "cm") {
        if (wh == "w") {
          return (num / dpi[0]) * 2.54;
        }
        if (wh == "h") {
          return (num / dpi[1]) * 2.54;
        }
      }
    },
    preview(info) {
      //console.log(info)
      this.form.value = info.url || info.response.url;
    },
    del(form) {
      this.printlist.forEach((item) => {
        this.$set(item, "isActive", false);
      });
      setTimeout(() => {
        var index = this.printlist.findIndex((item) => {
          return item.Id === this.form.Id;
        });
        //console.log(index, 'index')

        this.form = {};
        this.printlist.splice(index, 1);
      }, 100);
    },
    // 右侧保存
    formyes(form) {
      form.isActive = false;
      if (form.type === "4") {
        if (this.formobj.actionCol == "1") {
          if (
            !this.formobj.actionA ||
            !this.formobj.actionB ||
            !this.formobj.actionC ||
            !this.formobj.actionD
          ) {
            return this.$message.error("请完善激发事件选项");
          }
        }
        var ind = form.columns.findIndex((item) => {
          return item.dataIndex === this.formobj.dataIndex;
        });
        form.columns[ind] = this.formobj;
        // dataIndex 更替
        if (this.formobj.newDataIndex)
          form.columns[ind].dataIndex = this.formobj.newDataIndex;
        // 列排序
        form.columns = form.columns.sort((a, b) => {
          return (a.sortIndex || 0) - (b.sortIndex || 0);
        });
        // 自定义行数量
        if (form.datalist.length != form.rowCount) {
          form.datalist = [];
          for (let i = 0; i < form.rowCount; i++) {
            form.datalist.push({});
          }
        }
      }
      var index = this.printlist.findIndex((item) => {
        return item.Id === form.Id;
      });
      console.log(form);
      this.printlist[index] = form;
      this.form = {};
      this.formobj = {};
    },
    getCompanyIdList() {
      this.divloading = true;
      this.$http
        .post("/Base_Manage/Base_Company/GetDataCompanyData", {
          PageIndex: 1,
          PageRows: 9999,
          SortField: "Id",
          SortType: "desc",
          Search: {
            condition: "CreatorId",
            keyword: JSON.parse(sessionStorage.getItem("userInfo")).Id,
          },
        })
        .then((resJson) => {
          if (resJson.Success) {
            if (resJson.Data.length == 0) {
              this.$message.error("请先设置公司后进入");
              this.$router.go(-1);
              return;
            }
            this.CompanyId = resJson.Data[0].Id;
            this.changeSearchSelect(resJson.Data[0]);
            // 获取绑定数据源列
            this.getBindColList();
          }
          this.divloading = false;
        });
    },
    handleChange(info) {
      //console.log(info)
      if (info.file.status === "done") {
        var urllist = info.fileList
          .filter((x) => x.status == "done")
          .map((x) => x.url || x.response.url);
        this.form.value = urllist[0];
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 上传失败.`);
      }
    },
    isruleschange(a) {
      if (a) {
        this.$set(this.form, "ispringt", 0);
      } else {
        this.$set(this.form, "ispringt", 1);
      }
    },
    deactivated(a) {
      //console.log(a)
    },
    ispringtchange(a) {
      console.log(a);
      if (a) {
        this.$set(this.form, "isrules", 0);
      }
    },
    async geturl(item) { },
    async addprint(item) {
      this.printlist.forEach((val) => {
        this.$set(val, "isActive", false);
      });
      if (
        item.type === "8" ||
        item.type === "9" ||
        item.type === "10" ||
        item.type === "12"
      ) {
      } else {
        var index = this.printlist.findIndex((val) => {
          return item.Id === val.Id;
        });
        if (index !== -1) {
          this.printlist[index].isActive = true;
          this.form = { ...this.printlist[index] };
          return;
        }
      }
      if (item.type === "3") {
        //不查图片 ,手动上传图片
        if (!this.Type3UploadImgKeys.includes(item.Keys)) {
          await this.$http
            .post(
              `/Base_Manage/Base_Company/GetByEnclosure?Id=${this.CompanyId}&Keys=${item.Keys}`,
              {}
            )
            .then((res) => {
              if (item.type === "3") {
                item.value = res.Data;
              }
            });
          if (!item.value) {
            return this.$message.warning(`请上传${item.Key}后选择`);
          }
        }
      }

      if (item.type === "4") {
        this.columns.forEach((val, vx) => {
          this.$set(val, "width", 50);
          this.$set(val, "lineHeight", 20);
          this.$set(val, "fontSize", 18);
          this.$set(val, "bold", "initial");
          // this.$set(val, 'lineHeight', 28)
          this.$set(val, "align", "center");
          this.$set(val, "textalign", "center");
          this.$set(val, "textlineHeight", 40);
          this.$set(val, "type", "text");
          this.$set(val, "maxlength", "");
          this.$set(val, "decimals", "");
          this.$set(val, "sortIndex", vx + 1);
        });
        // 根据纸张赋值表格行数
        this.$set(item, "columns", this.columns);

        console.log("获取表格", item);

        if (item.rowCount) {
          for (let i = 0; i < item.rowCount; i++) {
            arr.push({});
          }
          this.$set(item, "datalist", arr);
        } else {
          if (this.keyType === "A4") {
            this.$set(item, "datalist", [{}, {}, {}, {}, {}, ...this.datalist]);
          } else {
            this.$set(item, "datalist", this.datalist);
          }
        }
        this.$set(item, "w", this.getpx(this.styleW, "w", "px") * 0.8);
        this.$set(item, "h", (item.datalist.length + 1) * 23);
      }
      this.$set(item, "index", this.printlist.length);
      console.log("获取点击", item);

      //收货方或签收人
      if (
        item.Keys === "CustomerName" ||
        item.Keys === "ContactPersonId" ||
        item.Keys === "CreateTime" ||
        item.Keys === "Company" ||
        item.Keys === "CarrierId"
      ) {
        this.$set(item, "isFormTrue", "true");
      } else {
        this.$set(item, "isFormTrue", "false");
      }
      if (item.type === "8" || item.type === "9" || item.type === "10") {
        this.$set(item, "pxh", 1);
      }
      this.$set(item, "ispringt", 1);
      if (item.type === "2" || item.type === "6") {
        this.$set(item, "ispringt", 0);
        this.$set(item, "isrules", 1);
      } else if (item.type === "4") {
        this.$set(item, "ispringt", 1);
      } else {
        this.$set(item, "ispringt", 1);
        this.$set(item, "isrules", 0);
      }

      this.$set(item, "parentW", this.getpx(this.styleW, "w", "px"));
      this.$set(item, "parentH", this.getpx(this.styleH, "h", "px"));
      // this.$set(item, 'isAlterTrue', 'true')
      var arr = [];
      var assobj = {};

      this.printlist.forEach((val) => {
        if (val.Keys == item.Keys) {
          arr.push(val);
        }
      });
      console.log(arr, "arr");
      this.form = {};
      if (arr.length > 0) {
        //console.log('true')
        var assobj2 = { ...item };
        this.$set(assobj2, "Id", item.Id + "-" + new Date().getTime());
        Object.assign(assobj2, { ...this.typeitem }, { ...assobj2 });

        this.printlist = [...this.printlist, assobj2];
      } else {
        //console.log('false')
        Object.assign(assobj, { ...this.typeitem }, { ...item });
        this.printlist = [...this.printlist, assobj];
      }
    },
    divcalck() {
      setTimeout(() => {
        this.printlist.forEach((item) => {
          this.$set(item, "isActive", false);
          this.form = {};
        });
      }, 10);
    },
    activated(item, index) {
      //console.log('组件外')
    },
    dragstop(a, item) {
      item.x = a.left;
      item.y = a.top;
      item.w = a.width;
      item.h = a.height;
      this.form = { ...item };
    },
    dragging(a, item) {
      item.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
      //console.log(a, '拖拽时')
    },
    resizestop(a, item) {
      item.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
      // this.form = {}
      this.form = { ...item };
      // console.log(a, '缩放结束')
    },
    resizing(a, item) {
      item.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
    },
    // 绘制区内组件点击
    clicked(item) {
      setTimeout(() => {
        this.printlist.forEach((val) => {
          this.$set(val, "isActive", false);
        });
        this.$set(item, "isActive", true);
        // 表格行数获取
        if (item.Keys == "table" && !item.rowCount) {
          this.$set(item, "rowCount", this.keyType == "A4" ? 10 : 5);
        }
        this.form = item;
        this.theadmodel = "";
      });
      console.log("绘制区内组件点击", item, this.form);
    },
    tablecallback(a) {
      // //console.log(a)
    },
    refprint() {
      //console.log(this.$refs.print.innerHTML)
      localStorage.setItem("print", this.$refs.print.innerHTML);
    },
    returnclick() {
      this.$router.go(-1);
    },
    open() {
      this.visible = true;
      this.getbutlist();
      this.printlist = [];
      this.form = {};
      // this.Amodel = "";
      // this.htmlval = localStorage.getItem('print')
    },
    // 获取数据源列
    getBindColList() {
      this.$http
        .post("/BO/BillList/GetIntityList?CompanyId=" + this.CompanyId)
        .then((res) => {
          this.colList = res.Data;
        });
    },
    selectDelModel(e) {
      this.delModleList = e;
    },
    // 删除确认弹窗
    handleOkToDelModel() {
      this.confirmDelModelLoading = true;
      this.$http
        .post("/Base/Base_Template/DeleteData", this.delModleList)
        .then(async (res) => {
          if (res.Success) {
            // TODO: 重新查模板，初始化模板
            // 获取列表并更新当前保存模板;
            await this.changeSearchSelect({
              Id: this.CompanyId,
            });
            this.TemNameChange(this.template[this.template.length - 1].Id);
            this.visibleConfirmDelModel = false;
            this.modalDelPop = false;
          } else {
          }
        })
        .finally(() => {
          this.confirmDelModelLoading = false;
        });
    },
    //删除模板
    modalDel() {
      if (this.delModleList.length) {
        this.visibleConfirmDelModel = true;
      } else {
        this.$message.error("请选择后删除");
      }
    },
    // 关闭删除模板前
    handleModalDelClose() { },
    dragstartHandler(ev, data) {

      const {
        // pageX,
        // pageY,
        offsetX,
        offsetY,
        // screenX,
        // screenY
      } = ev
      ev.dataTransfer.setData("application/app-data", JSON.stringify({ offsetX, offsetY, data }));
      ev.dataTransfer.effectAllowed = "move";
    },
    dragoverHandler(ev) {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "move";
    },
    dropHandler(ev) {
      ev.preventDefault();
      let data = ev.dataTransfer.getData("application/app-data");
      data = JSON.parse(data)
      data.data.x = ev.offsetX - data.offsetX
      data.data.y = ev.offsetY - data.offsetY
      this.addprint(data.data)
    },
    onDragEnd(ev) { },

    getHelperListStyle() {
      // 运行for循环查看1万以内适合的格子数  ！！！！ for 循环不可删除 i 代表可取的 格子数量（helperNumber）
      // for(var i=0;i<10000;i++){
      //   let col = Math.sqrt(i * 7 / 5)
      //   if(col === Math.floor(col)){
      //     let row = col*5/7
      //     console.log(col,row,i)
      //   }
      // }

      // let row = 50 // 列
      // let col = 70 // 行
      let col = Math.sqrt((this.helperNumber * 7) / 5);
      let row = (col * 5) / 7;

      return `width: calc( 100% / ${row} );height: calc(100% / ${col});`; /* 跨1列 */ /* 跨1行 */
    },
    updataImgUrl({ img }) {
      let list = this.printlist.filter((item) => item.isActive);
      if (list.length && img) {
        list[0].value = img;
        this.form.value = img;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-card-body {
  padding: 10px !important;
}

/deep/ .ant-form-item {
  margin-bottom: 1px;
}

.flexBox {
  display: flex;

  .flex1 {
    flex: 1;
  }
}

@page {
  size: auto;
  margin: 0mm !important;
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    -moz-print-color-adjust: exact !important;
    -ms-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

.print-helper {
  position: absolute;

  display: flex;
  flex-wrap: wrap;

  .print-helper-list {
    border-right: 1px dashed rgba(0, 0, 0, 0.06);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
  }
}
</style>

import { render, staticRenderFns } from "./domPrint.vue?vue&type=template&id=2bf308c3&scoped=true&"
import script from "./domPrint.vue?vue&type=script&lang=js&"
export * from "./domPrint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf308c3",
  null
  
)

export default component.exports
<template>
  <div style="padding: 20px">
    <el-row>
      <a-button type="primary" @click="returnclick">返回</a-button>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="表单模板" name="0">
        <formCode v-if="activeName === '0'"></formCode>
      </el-tab-pane>
      <el-tab-pane label="货品条码模板" name="1" v-if="IsSubAccount">
        <qrCode v-if="activeName === '1'"></qrCode>
      </el-tab-pane>
      <el-tab-pane label="对账函模板" name="2" v-if="IsSubAccount">
        <Reconciliation v-if="activeName === '2'"></Reconciliation>
      </el-tab-pane>
      <!-- <el-tab-pane label="货品信息条形码模板" name="2">
        <barCode v-if="activeName === '2'"></barCode>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import formCode from "./formCode.vue";
import qrCode from "./qrCode.vue";
import Reconciliation from "./rpage.vue";
// import barCode from './barCode.vue'
export default {
  data() {
    return {
      activeName: "0",
      IsSubAccount: false,
    };
  },
  props: {},
  components: {
    formCode,
    qrCode,
    Reconciliation,
    // barCode,
  },
  methods: {
    returnclick() {
      this.$router.go(-1);
    },
    handleClick(key) {
      //console.log(key)
    },
  },
  mounted() {},
  created() {
    var obj = JSON.parse(sessionStorage.getItem("userInfo"));
    if (obj.AccountType === "免费") {
      this.IsSubAccount = false;
    } else {
      if (!obj.IsSubAccount) {
        this.IsSubAccount = true;
      } else {
        this.IsSubAccount = false;
      }
    }
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <!-- <div class="button">
      <div class="img-list">
        <div
          class="el-images list"
          v-for="(item, inx) in imgList"
          :key="inx"
          style="width: 100px; height: 100px"
        >
          <el-image :src="item" :preview-src-list="imgList"> </el-image>
          <i
            v-if="canEdit"
            class="del el-icon-circle-close"
            @click="delImg(inx)"
          ></i>
        </div>

        <div v-if="canEdit" class="el-images" @click="drawer = true">
          <i class="el-icon-plus"></i>
        </div>
      </div>

      <div v-if="video" class="img-list">
        <div class="el-images video-model">
          <i @click="toggleFullscreen" class="el-icon-video-play"></i>

          <i
            v-if="canEdit"
            class="del el-icon-circle-close"
            @click.self="delVideo"
          ></i>
        </div>
        <video v-show="video && isFullscreen" ref="videoPlayer" controls>
          <source :src="video" type="video/mp4" />
        </video>
      </div>

    </div> -->
    <!-- <el-drawer
      :size="140"
      :visible.sync="drawer"
      :with-header="false"
      direction="btt"
    > -->
      <!-- 图片 -->
      <!-- <div class="upbtn" v-if="false">
        <input
          class="img-input"
          type="file"
          accept="image/*"
          capture="camera"
          id="takePicture"
          @change="handleFileSelectByImg"
        />
        <a-button type="primary" block>照片</a-button>
      </div> -->

      <!-- 视频 -->
      <!-- <div class="upbtn" v-if="false">
        <input
          class="img-input"
          type="file"
          accept="video/*"
          capture="camcorder"
          id="recordVideo"
          @change="handleFileSelectByVideo"
        />
        <a-button type="primary" block>视频</a-button>
      </div> -->

      <el-upload
        :action="$baseUrl + '/Base_Manage/Upload/UploadFileUserByForm'"
        capture="camcorder"
        accept="image/*"
        class="image-uploader"
        :headers="{
          Authorization: '',
        }"
        :show-file-list="false"
        :on-success="handleImgSuccess"
        :before-upload="beforeImgUpload"
      >
        <!-- :on-exceed="handleExceed"
        :file-list="fileList" -->
        <!-- :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove" -->
        <div class="upbtn">
          <a-button size="large" type="primary" block>上传图片</a-button>
        </div>
      </el-upload>

      <!-- <el-upload
        :action="$baseUrl + '/Base_Manage/Upload/UploadFileUserByForm'"
        capture="camcorder"
        accept="video/*"
        class="image-uploader"
        :headers="{
          Authorization: '',
        }"
        :show-file-list="false"
        :on-success="handleVideoSuccess"
        :before-upload="beforeVideoUpload"
      >
        <div class="upbtn">
          <a-button size="large" type="primary" block>视频</a-button>
        </div>
      </el-upload> -->
    <!-- </el-drawer> -->
  </div>
</template>
<script>
export default {
  components: {},
  // imgListParam 要求 以逗号分隔的字符串
  props: ["imgListParam", "videoParam", "canEdit"],
  data() {
    return {
      drawer: false,
      loading: null,
      imgList: [],
      video: "",

      isFullscreen: false,
      isEdit: true,
    };
  },

  watch: {
    
  },
  methods: {
    handleImgSuccess(res, file) {
      if (res.status === "done") {
        this.$emit("change", {
          img: res.url
        });
      } else {
        this.$message.error("上传失败,请重试");
      }
      this.loading.close();
    },
    beforeImgUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      console.log(file.size / 1024);
      let isLt2M = file.size / 1024 < 5000;
      console.log(isLt2M);

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG或者PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过5M!");
        return false;
      }

      this.loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        color: "#fff",
        background: "rgba(0, 0, 0, 0.3)",
      });
      if(isJPG && isLt2M){
        return new Promise((resolve,reject)=>{
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              console.log(img.width,img.height);
              const ratio = img.width/img.height
              const baseSize = 500 //原始宽度值
              canvas.width = baseSize; // 设置压缩后图片的宽度
              // canvas.height = 300; // 根据原始图片比例计算压缩后图片的高度
              canvas.height = baseSize / ratio; // 根据原始图片比例计算压缩后图片的高度
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              canvas.toBlob((blob) => {
                const compressedFile = new File([blob], file.name, { type: file.type, lastModified: Date.now() });
                resolve(compressedFile);
              }, file.type);
            };
          };
        })
      }
      return isJPG && isLt2M;
    },
  },
};

//
</script>
<style scoped lang="less">
.image-uploader /deep/ .el-upload {
  width: 100%;
}

.image-uploader /deep/ .el-upload-list {
  display: none;
}
.image-uploader /deep/ input{
	display: none;
}

.upbtn {
  margin: 10px 10px 0;
  position: relative;
  .img-input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 1px solid red;
  }
}
.el-images {
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  margin-left: 10px;
  text-align: center;
  line-height: 100px;
  font-size: 60px;
  font-weight: bold;
  color: #ddd;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-image {
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;

  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.img-list {
  display: flex;
  flex-wrap: wrap;
}
.video-model {
  width: 100px;
  height: 100px;
  background: #000;
  border-radius: 8px;
  color: #fff;
  font-size: 34px;
  text-align: center;
  align-items: center;
  line-height: 100px;
  border: 1px solid #000;
}
.del {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 23px;
}
</style>

<!-- this.canvasCtx = uni.createCanvasContext('canvas_sign', this) //创建绘图对象
			//设置画笔样式
			this.canvasCtx.lineWidth = 4
			// 设置线条的端点样式
			this.canvasCtx.lineCap = 'round'
			// 设置线条的交点样式
			this.canvasCtx.lineJoin = 'round' -->
